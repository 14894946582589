import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsPageModule } from '@design-system/feature/page';
import { DsSnackbarComponent } from '@design-system/feature/snackbar';
import { TranslateModule } from '@ngx-translate/core';
const sharedModules = [
  CommonModule,
  DsSpacingModule,
  FlexLayoutModule,
  FormsModule,
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatFormFieldModule,
  MatProgressBarModule,
  MatSelectModule,
  MatTableModule,
  MatTooltipModule,
  DsPageModule,
  ReactiveFormsModule,
  DsSnackbarComponent,
  TranslateModule,
  PdLetDirectiveModule,
];

@NgModule({
  imports: [...sharedModules],
  exports: [...sharedModules],
})
export class SharedModule {}
